// import { isDate } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { format, fromZonedTime } from 'date-fns-tz';

export const formatText = (string: string | null): string => {
  if (!string) return '';
  const formattedText = string.replace(/\s,/g, ',');
  return formattedText;
};

export const toSentenceCase = (str: string) => {
  // Replace underscores with spaces and convert to lowercase
  let result = str.replace(/_/g, ' ').toLowerCase();

  // Capitalize the first letter
  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
};

export const formatUSDate = (
  date: Date | null | string,
  hours: boolean = true,
  timezone: string = 'Etc/UTC'
): string => {
  if (!date) return '';

  // seconds have to be added to the date to make it work properly
  // as backend is returning dates with seconds and in forms
  // we are checking if date has changed, if we compare with and without seconds
  // we will always allow user to save the form
  // with modified value of any date field
  if (hours) {
    return formatInTimeZone(date, timezone, 'MM/dd/yyyy, hh:mm:ss a', {
      locale: enUS,
    });
  } else {
    return formatInTimeZone(date, timezone, 'MM/dd/yyyy', {
      locale: enUS,
    });
  }
};

export const formatLicensePlate = (licensePlate: string): string => {
  if (licensePlate.length < 4) return licensePlate;
  return licensePlate.slice(0, 3) + '-' + licensePlate.slice(3);
};

export const constructReportFileName = (
  header: string,
  filters: any = null
): string => {
  let fileName = `${formatUSDate(
    new Date(),
    true,
    useAuthStore().timezone
  )}-${header}`;

  if (filters.global.value) {
    fileName += `-global_search=${filters.global.value}`;
  }

  return fileName;
};

export const sanitizeReportData = (data: any) => {
  return data.map((row: any) => {
    return {
      ...row,
      license_plate: formatLicensePlate(row.license_plate),
      status: toSentenceCase(row.status),
      tow_date: formatUSDate(row.tow_date, true),
      tow_completed_date: formatUSDate(row.tow_completed_date, true),
    };
  });
};

export const construcChangesMessage = (changes: any): string => {
  const changesArray = Object.keys(changes)
    .filter((key) => key !== 'id')
    .map((key) => `${toSentenceCase(key)}=${changes[key]}`);

  return `(${changesArray.join(', ')})`;
};

export const formatFrontEndDateToUtc = (
  value: Date | undefined | null,
  timezone: string
) => {
  if (!value) {
    return null;
  }

  if (value) {
    // May 28 2024 07:03:14 GMT+0200 (czas środkowoeuropejski letni)
    // right now value is as selected but with information about local browser timezone

    // here we are removing any information about timezone (because timezone is known from context of given page (authStore.timezone))
    const formattedDate = format(value, "yyyy-MM-dd'T'HH:mm:ss.SSS");
    // 2024-05-28T07:03:14.000

    // here we are shifting selected date to local browser timezone
    const date = fromZonedTime(formattedDate, timezone);
    // Tue May 28 2024 14:03:14 GMT+0200 (czas środkowoeuropejski letni)

    // here we are shifting selected date to UTC timezone
    const isoString = date.toISOString();
    // 2024-05-28T12:03:14.000Z

    return isoString;
  }
};

export const getCurrentDateTimezone = (
  date: Date | undefined | null,
  timezone: string
) => {
  if (!date) {
    return null;
  }

  const tzDate = formatInTimeZone(date, timezone, 'MM/dd/yyyy, hh:mm a', {
    locale: enUS,
  });

  return tzDate;
};

export const trimQueryValues = (query: Object) => {
  for (const filterObject of Object.values(query)) {
    if (typeof filterObject.value === 'string') {
      filterObject.value = filterObject.value.trim();
    }
  }
};
